<template>
    <div class="changePassword">
        <van-tabs v-model="active" color="#3366FF">
            <van-tab title="设置密码">
                <div class="container">
                    <van-form @submit="onSubmit" class="user">
                        <van-cell-group inset>
                            <p class="user-name">原密码</p>
                            <van-field v-model="oldpass" left-icon="user-o" type="password" name="oldpass" placeholder="请输入原密码" />

                            <p class="user-name">新密码</p>
                            <van-field v-model="newpass" left-icon="bag-o" type="password" name="newpass" placeholder="请输入新密码" />

                            <p class="user-name">确认新密码</p>
                            <van-field v-model="re_newpass" left-icon="user-o" type="password" name="re_newpass" placeholder="请再次输入新密码" />
                        </van-cell-group>

                        <div class="user-btn">
                            <van-button block type="primary" native-type="submit">确定</van-button>
                        </div>
                    </van-form>
                </div>
            </van-tab>
            <van-tab title="设置支付密码">
                <div class="container">
                    <van-form @submit="onSubmit2" class="user">
                        <van-cell-group inset>
                            <p class="user-name" v-show="userInfo.ISSET_DRAWPASS !== 0">原支付密码</p>
                            <van-field v-show="userInfo.ISSET_DRAWPASS !== 0" v-model="oldpass2" left-icon="user-o" type="password" name="oldpass2" placeholder="请输入原支付密码" />

                            <p class="user-name">新支付密码</p>
                            <van-field v-model="newpass2" left-icon="bag-o" type="password" name="newpass2" placeholder="请输入新支付密码" />

                            <p class="user-name">确认新支付密码</p>
                            <van-field v-model="re_newpass2" left-icon="user-o" type="password" name="re_newpass2" placeholder="请再次输入新支付密码" />
                        </van-cell-group>

                        <div class="user-btn">
                            <van-button block type="primary" native-type="submit">确定</van-button>
                        </div>
                    </van-form>
                </div>
            </van-tab>
        </van-tabs>        
    </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { changePassword, changePayPassword } from '@/api'
import md5 from 'js-md5'
import { Toast } from 'vant'

export default {
    setup() {
        const state = reactive({
            oldpass: '',
            newpass: '',
            re_newpass: '',
            oldpass2: '',
            newpass2: '',
            re_newpass2: ''
        })

        const router = useRouter()
        const store = useStore()

        const onSubmit = params => {
            if (!params.oldpass) {
                Toast.fail('请输入旧密码！')
                return
            } 
            if (!params.newpass) {
                Toast.fail('请输入新密码！')
                return
            }
            if (!params.re_newpass) {
                Toast.fail('请确认新密码！')
                return
            }
            if (params.newpass !== params.re_newpass) {
                Toast.fail('两次输入的新密码不一样！')
                return
            }
            changePassword({
                oldpass: md5(params.oldpass),
                newpass: md5(params.newpass)
            }).then(res => {
                if (res.status) {
                    Toast.success('登录密码设置成功！')
                    router.back()
                } else {
                    Toast.fail(res.message)
                }
                state.oldpass = state.newpass = state.re_newpass = ''
            })
        }

        const onSubmit2 = params => {
            if (!params.oldpass2 && store.state.userInfo.ISSET_DRAWPASS !== 0) {
                Toast.fail('请输入旧支付密码！')
                return
            } 
            if (!params.newpass2) {
                Toast.fail('请输入新支付密码！')
                return
            }
            if (!params.re_newpass2) {
                Toast.fail('请确认新支付密码！')
                return
            }
            if (params.newpass2 !== params.re_newpass2) {
                Toast.fail('两次输入的新支付密码不一样！')
                return
            }
            changePayPassword({
                oldpass: md5(params.oldpass2),
                newpass: md5(params.newpass2)
            }).then(res => {
                if (res.status) {
                    Toast.success('支付密码设置成功！')
                    localStorage.removeItem(GOPAY_USERINFO)
                    router.push('/Home')
                } else {
                    Toast.fail(res.message)
                }
                state.oldpass2 = state.newpass2 = state.re_newpass2 = ''
            })
        }

        return {
            ...toRefs(state),
            router,
            onSubmit,
            onSubmit2,
            userInfo: computed(() => store.state.userInfo)
        }
    }
}
</script>

<style lang="scss" scoped>
.changePassword {
    .container{
        padding: 0 w(30);
    }
    .user {
        margin-top: h(19);
        font-size: 10px;

        .user-name {
            margin-top: h(21);
            text-align: left;
            color: #333333;
            font-size: 14px;
            line-height: 18px;
        }

        .mima {
            margin-top: h(15);
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-end;

            .user-name {
                margin-bottom: h(8);
            }

            .jzmm {
                margin-right: w(10);
                vertical-align: super;
                font-size: 12px;
                color: #828282;
            }
        }

        .user-btn {
            margin-top: h(58);
            font-size: 18px;
        }
    }
}
</style>
